import { Component, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
    selector: 'app-signout',
    templateUrl: './signout.component.html',
    styleUrls: ['./signout.component.scss'],
})
export class SignoutComponent implements OnInit {
    constructor(private oauthService: OAuthService) {}

    ngOnInit(): void {
        // The only reason why this is here is because we cannot redirect external through the router
        this.oauthService.initImplicitFlow();
    }
}
