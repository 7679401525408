import { Directive } from '@angular/core';
import { AbstractControl, Validator, NG_VALIDATORS, ValidationErrors } from '@angular/forms';

@Directive({
    /* eslint-disable-next-line @angular-eslint/directive-selector */
    selector: '[decimal]',
    providers: [{ provide: NG_VALIDATORS, useExisting: DecimalValidator, multi: true }],
})
export class DecimalValidator implements Validator {
    static decimal(c: AbstractControl): ValidationErrors {
        if (c.value === null) {
            return { decimal: true };
        }

        const value = c.value.toString().replace(/,/g, '.');
        const validNumber = /^[.\d]+$/.test(value) ? +c.value : NaN;

        if (!validNumber) {
            return { decimal: true };
        }

        return null;
    }

    validate(c: AbstractControl): { [key: string]: any } {
        return DecimalValidator.decimal(c);
    }
}
