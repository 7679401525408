import { ISupportedCountry } from './supported-country';

export interface IAppConfig {
    rowLimit: number;
    supportedCountries: {
        [key: string]: ISupportedCountry;
    };
    validationRegex: {
        phone: RegExp;
        ibanNumber: RegExp;
        bicCode: RegExp;
    };
    calendarLocale: {
        firstDayOfWeek: number;
        dayNames: Array<string>;
        dayNamesShort: Array<string>;
        dayNamesMin: Array<string>;
        monthNames: Array<string>;
        monthNamesShort: Array<string>;
        today: string;
        clear: string;
    };
}
