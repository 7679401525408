import { Action } from '@ngrx/store';
import { actionType } from '../../../../utils';

export const SET_DEFAULT_LANGUAGE = actionType('[Translate] Set default language');
export const SET_DEFAULT_LANGUAGE_FAILURE = actionType('[Translate] Could not set default language');
export const SET_DEFAULT_LANGUAGE_SUCCESS = actionType('[Translate] Successfully set default language');

export const SET_CURRENT_LANGUAGE = actionType('[Translate] Set current language');
export const SET_CURRENT_LANGUAGE_FAILURE = actionType('[Translate] Could not set current language');
export const SET_CURRENT_LANGUAGE_SUCCESS = actionType('[Translate] Successfully set current language');

export const LOAD_TRANSLATION = actionType('[Translate] Load translation');
export const LOAD_TRANSLATION_FAILURE = actionType('[Translate] Could not load translation');
export const LOAD_TRANSLATION_SUCCESS = actionType('[Translate] Loaded translation succesfully');

export class SetDefaultLanguage implements Action {
    readonly type = SET_DEFAULT_LANGUAGE;
    constructor(public payload: string) {}
}

export class SetDefaultLanguageFailure implements Action {
    readonly type = SET_DEFAULT_LANGUAGE_FAILURE;
    constructor(public payload: any) {}
}

export class SetDefaultLanguageSuccess implements Action {
    readonly type = SET_DEFAULT_LANGUAGE_SUCCESS;
    constructor(public payload: string) {}
}

export class SetCurrentLanguage implements Action {
    readonly type = SET_CURRENT_LANGUAGE;
    constructor(public payload: string) {}
}

export class SetCurrentLanguageFailure implements Action {
    readonly type = SET_CURRENT_LANGUAGE_FAILURE;
    constructor(public payload: any) {}
}

export class SetCurrentLanguageSuccess implements Action {
    readonly type = SET_CURRENT_LANGUAGE_SUCCESS;
    constructor(public payload: string) {}
}

export class LoadTranslation implements Action {
    readonly type = LOAD_TRANSLATION;
    constructor(public payload: string) {}
}

export class LoadTranslationFailure implements Action {
    readonly type = LOAD_TRANSLATION_FAILURE;
    constructor(public payload: any) {}
}

export class LoadTranslationSuccess implements Action {
    readonly type = LOAD_TRANSLATION_SUCCESS;
    constructor(public payload: string) {}
}

// action types
export type TranslateActions =
    | SetDefaultLanguage
    | SetDefaultLanguageFailure
    | SetDefaultLanguageSuccess
    | SetCurrentLanguage
    | SetCurrentLanguageFailure
    | SetCurrentLanguageSuccess
    | LoadTranslation
    | LoadTranslationFailure
    | LoadTranslationSuccess;
