import { Injectable, Inject } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';

import { Observable, of, throwError } from 'rxjs';
import { exhaustMap } from 'rxjs/operators';

import { LogService } from '@fp/ngx-log';

import { IClaims } from '../../shared';
import { AuthModuleConfig } from '../interfaces/module';
import { AuthService } from '../services';

@Injectable({
    providedIn: 'root',
})
export class RoleGuard implements CanActivate {
    protected readonly logPrefix: string = '[RoleGuard]';

    constructor(
        @Inject('authConfig') private config: AuthModuleConfig,
        private logService: LogService,
        private authService: AuthService,
        private router: Router
    ) {}

    canActivate(next: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        this.logService.info(this.logPrefix, 'Initialize');

        return this.authService.loadSharedDiscoveryDocument().pipe(
            exhaustMap(() => this.authService.loadUserProfile()),
            exhaustMap((claims: IClaims) => {
                if (next.data.role === undefined) {
                    return throwError('No role data defined');
                }

                const allowedRole = next.data.role !== undefined && next.data.role.indexOf(claims.role) !== -1;

                this.logService.info(this.logPrefix, 'role is allowed', allowedRole);

                if (allowedRole) {
                    return of(true);
                }

                // Redirect to dashboard
                this.router.navigate(this.config.unauthorizedRedirect);

                return of(false);
            })
        );
    }
}
