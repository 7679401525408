import { DateUtils } from '@bucke/shared/utils/date-utils';
import { TranslateService } from '@ngx-translate/core';
import { Campaign } from 'app/admin/tenants/interfaces';
import * as d3 from 'd3';

let div;
let tooltip;

export class GanttChart {
    chartState: WeakMap<GanttChart, GanttChartOptions> = new WeakMap();
    options: any;
    x: any;
    heightChart: number;

    constructor(private translateService: TranslateService, options: GanttChartOptions) {
        this.calculateHeightAndWidth(options);
        this.options = options;

        const state: GanttChartOptions = this.getState();

        d3.timeFormatDefaultLocale({
            decimal: ',',
            thousands: '.',
            grouping: [3],
            currency: ['€', ''],
            dateTime: '%a %b %e %X %Y',
            date: '%d.%m.%Y',
            time: '%H:%M:%S',
            periods: ['AM', 'PM'],
            days: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
            shortDays: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
            shortMonths: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
            months: options.monthsLocale,
        } as any);

        const root = d3.select(`${options.container}`);
        const svg = root.append('svg').attr('width', options.svgWidth).attr('height', options.svgHeight);

        const focus = svg.append('g').attr('class', 'focus').attr('transform', `translate(${options.margin.left}, ${options.margin.top})`);

        const focusYAxis = focus.append('g').attr('class', 'axis y-axis');

        const focusXAxis = focus.append('g').attr('class', 'axis x-axis').attr('transform', `translate(0, ${state.height})`);

        const context = svg
            .append('g')
            .attr('class', 'context')
            .attr('transform', `translate(${options.margin2.left}, ${options.margin2.top})`);

        const contextXAxis = context.append('g').attr('class', 'axis x-axis').attr('transform', `translate(0, ${state.height2})`);

        const defs = svg
            .append('defs')
            .append('clipPath')
            .attr('id', 'clip')
            .append('rect')
            .attr('width', Math.abs(state.width))
            .attr('height', state.svgHeight);

        const color = d3.scaleOrdinal(d3.schemeCategory10);

        //Container for the gradients
        const glowDefs = svg.append('defs');

        //Filter for the outside glow rollover behaviour
        const filter = glowDefs.append('filter').attr('id', 'glow');
        filter.append('feGaussianBlur').attr('stdDeviation', '1.5').attr('result', 'coloredBlur');
        const feMerge = filter.append('feMerge');
        feMerge.append('feMergeNode').attr('in', 'coloredBlur');
        feMerge.append('feMergeNode').attr('in', 'SourceGraphic');

        Object.assign(options, {
            root,
            svg,
            focus,
            focusXAxis,
            focusYAxis,
            context,
            contextXAxis,
            defs,
            color,
        });

        this.setState(options);
    }

    click(id) {
        return id;
    }

    brushed() {
        const state = this.getState();

        if (d3.event.sourceEvent && d3.event.sourceEvent.type === 'zoom') {
            return;
        } // ignore brush-by-zoom

        state.focusXAxis.call(state.xAxisDraw);

        this.redrawFocused();
    }

    zoomed() {
        const state = this.getState();

        if (d3.event.sourceEvent && d3.event.sourceEvent.type === 'brush') {
            return;
        } // ignore zoom-by-brush

        const t = d3.event.transform;

        this.redrawFocused();

        state.focusXAxis.call(state.xAxisDraw);
    }

    build(data = this.getState().data) {
        const self = this;
        const state = this.getState();

        this.setData(data).axisDraw().tasksEnter().tasksUpdate();

        return self;
    }

    getState() {
        const self = this;

        return this.chartState.get(self) || new GanttChartOptions();
    }

    setState(newState: GanttChartOptions) {
        const currentState = this.getState();
        this.chartState.set(this, Object.assign(currentState, newState));

        return this;
    }

    private calculateHeightAndWidth(options: GanttChartOptions) {
        const width = +options.svgWidth - options.margin.left - options.margin.right;
        const height = +options.svgHeight - options.margin.top - options.margin.bottom;
        this.heightChart = height;
        const height2 = +options.svgHeight - options.margin2.top - options.margin2.bottom;

        Object.assign(options, { width, height, height2 });
        this.setState(options);
    }

    private setData(data = this.getState().data) {
        const state = this.getState();

        state.data = data;
        state.tasks = state.svg.selectAll('.task').data(data, (d) => `${d.category}~${d.name}`);

        this.setState(state);

        tooltip = d3.select('.gantt-chart-tooltip');

        return this;
    }

    private axisDraw() {
        const state = this.getState();

        const data = state.data || [];

        this.x = d3
            .scaleTime()
            .domain([d3.min(data.map(() => new Date(state.domainMin))), d3.max(data.map(() => new Date(state.domainMax)))])
            .range([0, state.width])
            .nice();

        const y = d3
            .scaleBand()
            .domain(data.map((d) => d.category))
            .rangeRound([0, state.height])
            .padding(state.barSpacing);

        const xAxis = d3
            .axisBottom(this.x)
            .tickFormat(d3.timeFormat('%B'))
            .tickSizeInner(-55 * data.length);

        const yAxis = d3.axisLeft(y).tickSizeInner(-95 * 10);

        if (d3.select('.gantt-chart-tooltip').size() === 0) {
            div = d3.select('body').append('div').attr('class', 'tooltip gantt-chart-tooltip');
        }

        Object.assign(state, {
            xScale: this.x,
            yScale: y,
            yAxisDraw: yAxis,
            xAxisDraw: xAxis,
            focusXAxis: state.focusXAxis.call(xAxis),
            focusYAxis: state.focusYAxis.call(yAxis),
        });

        this.setState(state);

        return this;
    }

    private tasksEnter() {
        const state = this.getState();
        const translateService = this.translateService;

        const focusTaskGroup = state.focus.append('g').attr('class', 'task-group');

        const focusTasks = focusTaskGroup.selectAll('.task').data(state.data).enter().append('g').attr('class', 'task');

        focusTasks
            .append('rect')
            .attr('class', 'bar')
            .attr('rx', 6)
            .attr('ry', 6)
            .attr('x', (d) => state.xScale(new Date(d.from)))
            .attr('y', (d) => state.yScale(d.category))
            .attr('fill', (d, i) => d.color)
            .attr('width', 0)
            .attr('height', (d) => Math.abs(state.yScale.bandwidth() / 2))
            .on('mouseover', function (d: Campaign) {
                const properties: Array<{
                    label: string;
                    property?: number | string;
                    styleClass?: string;
                }> = [
                    {
                        label: 'CAMPAIGN_CHART_TOOLTIP_START_DATE',
                        property: d.startDate,
                        styleClass: 'gantt-chart-row gantt-chart-border',
                    },
                    {
                        label: 'CAMPAIGN_CHART_TOOLTIP_END_DATE',
                        property: d.untilDate,
                        styleClass: 'gantt-chart-row gantt-chart-border',
                    },
                    {
                        label: 'CAMPAIGN_CHART_TOOLTIP_POINTS_PER_COIN',
                        property: d.pointsPerCoin,
                        styleClass: 'gantt-chart-row gantt-chart-border',
                    },
                    {
                        label: 'CAMPAIGN_CHART_TOOLTIP_WALKING_TAGS',
                        styleClass: 'gantt-chart-row gantt-chart-border',
                    },
                    {
                        label: 'CAMPAIGN_CHART_TOOLTIP_WALKING_DISTANCE_THRESHOLD',
                        property: d.walkDistanceThreshold,
                    },
                    {
                        label: 'CAMPAIGN_CHART_TOOLTIP_WALKING_POINTS_PER_DAY',
                        property: d.walkPointsPerDay,
                    },
                    {
                        label: 'CAMPAIGN_CHART_TOOLTIP_WALKING_POINTS_DISTANCE_THRESHOLD',
                        property: d.walkPointsDistanceThreshold,
                    },
                    {
                        label: 'CAMPAIGN_CHART_TOOLTIP_CYCLING_TAGS',
                        styleClass: 'gantt-chart-row gantt-chart-border',
                    },
                    {
                        label: 'CAMPAIGN_CHART_TOOLTIP_CYCLING_DISTANCE_THRESHOLD',
                        property: d.bicycleDistanceThreshold,
                    },
                    {
                        label: 'CAMPAIGN_CHART_TOOLTIP_CYCLING_POINTS_PER_DAY',
                        property: d.bicyclePointsPerDay,
                    },
                    {
                        label: 'CAMPAIGN_CHART_TOOLTIP_CYCLING_POINTS_DISTANCE_THRESHOLD',
                        property: d.bicyclePointsDistanceThreshold,
                    },
                    {
                        label: 'CAMPAIGN_CHART_TOOLTIP_EXTRA_POINTS_ON_BAD_WEATHER_DAY',
                        property: d.extraPointsOnBadWeatherDay,
                        styleClass: 'gantt-chart-border',
                    },
                ];

                const tooltipAttribute = (label: string, property?: string | number, styleClass?: string) => `
          <div class="p-grid gantt-chart-row ${!!styleClass ? styleClass : ''}">
            <div class="p-col-8 text-left">${translateService.instant(label)}</div>
            <div class="p-col-4 text-right">${!!property ? property : ''}</div>
          </div>
        `;

                const content = properties.map((value) => tooltipAttribute(value.label, value.property, value.styleClass)).join('');

                tooltip.html(`
          <div class="gantt-chart-tooltip-body">
            <div class="gantt-chart-title">${d.name}</div>
            ${content}
          </div>
        `);

                d3.select(this).style('cursor', 'pointer').style('filter', 'url(#glow)');

                div.transition()
                    .duration(200)
                    .style('opacity', 1)
                    .style('left', () => {
                        const leftCoordinate = d3.mouse(this)[0];
                        const leftPosition = window.innerWidth - leftCoordinate < 450 ? leftCoordinate - 100 : leftCoordinate + 200;

                        return `${leftPosition}px`;
                    })
                    .style('top', () => {
                        const topCoordinate = d3.mouse(this)[1];

                        return `${topCoordinate + 175}px`;
                    });
            })
            .on('click', (d) => {
                d.selected = true;
                state.data.map((e) => {
                    d.selected = d.campaignId === e.campaignId;
                });
            })
            .on('mouseout', function (d) {
                d3.select(this).style('cursor', 'default').style('filter', null);
                div.transition().duration(500).style('opacity', 0);
            });

        d3.selectAll('.tick').select('line').style('opacity', 0.1).attr('transform', 'translate(0,0)');

        d3.selectAll('.x-axis').selectAll('.tick').select('text').attr('transform', 'translate(0,10)');

        d3.selectAll('.x-axis').selectAll('.tick').select('text').style('opacity', 0.7).style('top', 20);

        d3.selectAll('.y-axis')
            .selectAll('.tick')
            .data(state.data)
            .each(function (d) {
                d3.select(this)
                    .attr('font-size', '12px')
                    .attr('padding-top', '-5px')
                    .append('svg:foreignObject')
                    .attr('background-color', '#FFFFFF')
                    .attr('x', -30)
                    .attr('y', -8)
                    .attr('width', 17)
                    .attr('height', 17)
                    .html('<i class="pi pi-pencil"></i>')
                    .attr('color', '#FF0000')
                    .on('click', (d2: any) => {
                        const event = new CustomEvent('select', { detail: d2.campaignId });
                        document.getElementById('chart').dispatchEvent(event);
                    })
                    .on('mouseover', () => {
                        d3.select(this).style('cursor', 'pointer').attr('color', '#c30045');
                    })
                    .on('mouseout', () => {
                        d3.select(this).style('cursor', 'default').attr('color', '#000000');
                    });
            });

        const today = new Date();

        if (DateUtils.dateIsDuringSchoolPeriod(today)) {
            /**
             * Draw Today as dashed red line
             */
            d3.select('.task-group')
                .append('line')
                .attr('x1', () => this.x(today) || 0)
                .attr('y1', 0)
                .attr('x2', () => this.x(today) || 0)
                .attr('y2', this.heightChart)
                .style('stroke-width', 1)
                .style('stroke', '#FF0000')
                .style('stroke-dasharray', 5)
                .style('fill', 'none');
        }

        /**
         * Draw vertical lines
         */
        // state.data.map(d => d.category)
        d3.select('.task-group')
            //xScale.domain(state.data.map(d => d.category))
            .data(state.data.map((d) => d.category))
            .enter()
            .append('g')
            .attr('class', 'task');

        d3.selectAll('.y-axis').selectAll('text').call(this.wrap, 100);

        Object.assign(state, {
            focusTasks,
        });

        this.setState(state);

        return this;
    }

    /**
     * Ensure the Y-as labels are split and centered when too long
     */
    private wrap(text, width) {
        text.each(function () {
            const text2 = d3.select(this);
            const words = text2.text().split(/\s+/).reverse();
            let word;
            let line = [];
            let lineNumber = 0;
            const lineHeight = 1.1;
            const y = text2.attr('y');
            const dy = parseFloat(text2.attr('dy'));
            let tspan = text2
                .text(null)
                .append('tspan')
                .attr('x', -45)
                .attr('y', y)
                .attr('dy', dy + 'em');

            word = words.pop();

            while (word) {
                line.push(word);
                tspan.text(line.join(' '));

                if (tspan.node().getComputedTextLength() > width) {
                    line.pop();
                    tspan.text(line.join(' '));
                    line = [word];
                    tspan = text2
                        .append('tspan')
                        .attr('x', -45)
                        .attr('y', y)
                        .attr('dy', ++lineNumber * lineHeight + dy + 'em')
                        .text(word);
                }

                word = words.pop();
            }

            text2.attr('y', -(lineNumber * 20) / 2);
        });
    }

    private tasksUpdate() {
        const state = this.getState();
        const focusTask = state.focusTasks;

        const focusTaskUpdate = focusTask
            .selectAll('.bar')
            .attr('x', (d) => state.xScale(new Date(d.from)))
            .attr('y', (d) => state.yScale(d.category))
            .attr('width', (d) => Math.abs(state.taskWidth(d)))
            .attr('height', (d) => Math.abs(state.yScale.bandwidth() / 2))
            .attr('transform', `translate(${state.yScale.range()[0]}, ${state.yScale.bandwidth() / 4})`);

        Object.assign(state, {
            focusTaskUpdate,
        });

        this.setState(state);

        return this;
    }

    private updateColor() {
        const state = this.getState();
        state.focusTasks.selectAll('.task-group').attr('fill', (d, i) => state.color(i));
    }

    private attachBrush() {
        const state = this.getState();

        state.brush = d3
            .brushX()
            .extent([
                [0, 0],
                [state.width, state.height2 - 2],
            ])
            .on('brush end', this.brushed.bind(this));

        this.setState(state);

        return this;
    }

    private attachZoom() {
        const state = this.getState();
        state.zoom = d3
            .zoom()
            .scaleExtent([1, Infinity])
            .translateExtent([
                [0, 0],
                [state.width, state.height],
            ])
            .extent([
                [0, 0],
                [state.width, state.height],
            ])
            .on('zoom', this.zoomed.bind(this));

        state.svg
            .append('rect')
            .attr('class', 'zoom')
            .attr('opacity', 0.1)
            .attr('width', Math.abs(state.width))
            .attr('height', Math.abs(state.height))
            .attr('transform', `translate(${state.margin.left}, ${state.margin.top})`)
            .call(state.zoom);

        this.setState(state);

        return this;
    }

    private tasksExit() {
        const state = this.getState();

        const focusTask = state.focusTasks;
        const focusTaskExit = focusTask.selectAll('.bar').data(state.data).exit().remove();

        Object.assign(state, {
            focusTaskExit,
        });

        this.setState(state);

        return this;
    }

    private redrawFocused() {
        const state = this.getState();
        const focusTask = state.focusTasks;

        const tasks = focusTask.select('.task-group').selectAll('.task');

        const focusTaskUpdate = focusTask
            .selectAll('.bar')
            .attr('x', (d) => state.xScale(new Date(d.from)))
            .attr('y', (d) => state.yScale(d.category))
            .attr('width', (d) => Math.abs(state.taskWidth(d)))
            .attr('height', (d) => Math.abs(state.yScale.bandwidth() / 2))
            .attr('transform', `translate(${state.yScale.range()[0]}, ${state.yScale.bandwidth() / 4})`);

        Object.assign(state, {
            focusTaskUpdate,
        });

        this.setState(state);

        return this;
    }
}

export class GanttChartOptions implements IGanttChartOptions {
    container: string;
    data: Array<any>;
    svgWidth: number | string;
    svgHeight: number | string;
    margin: { [key: string]: number };
    margin2: { [key: string]: number };
    barSpacing: number;
    width?: number;
    height?: number;
    height2?: number;
    focus?: any;
    domainMin?: any;
    domainMax?: any;
    monthsLocale?: Array<string>;

    root?: any;
    svg?: any;
    tasks?: any;
    focusTasks?: any;
    focusTaskUpdate?: any;
    focusTaskExit?: any;

    color?: any;
    xScale?: any;
    yScale?: any;
    yAxisDraw?: any;
    xAxisDraw?: any;
    focusXAxis?: any;
    focusYAxis?: any;
    contextXAxis?: any;
    defs?: any;
    brush?: any;
    zoom?: any;

    click?: any;

    constructor(
        container: string = '#chart',
        data: any = [],
        svgHeight: number = 0,
        svgWidth: number = 0,
        margin: any = {},
        margin2: any = {},
        domainMin: any = '',
        domainMax: any = '',
        monthsLocale: Array<string> = [],
        barSpacing: number = 0.25
    ) {
        this.container = container;
        this.data = data;
        this.svgHeight = svgHeight;
        this.svgWidth = svgWidth;
        this.margin = margin;
        this.margin2 = margin2;
        this.domainMin = domainMin;
        this.domainMax = domainMax;
        this.barSpacing = barSpacing;
        this.monthsLocale = monthsLocale;
    }

    taskWidth(d: any) {
        return this.xScale(new Date(d.to)) - this.xScale(new Date(d.from));
    }
}

export interface IGanttChartOptions {
    container: string;
    data: Array<any>;
    svgWidth: number | string;
    svgHeight: number | string;
    margin: { [key: string]: number };
    margin2: { [key: string]: number };
    barSpacing: number;
    root?: any;
    svg?: any;
    width?: number;
    height?: number;
    height2?: number;
    focus?: any;
    context?: any;
    domainMin?: any;
    domainMax?: any;
    monthsLocale?: Array<string>;

    tasks?: any;
    focusTasks?: any;
    contextTask?: any;
    contextTaskUpdate?: any;
    focusTaskUpdate?: any;
    focusTaskExit?: any;

    xScale?: any;
    yScale?: any;
    yAxisDraw?: any;
    xAxisDraw?: any;
    focusXAxis?: any;
    focusYAxis?: any;
    color?: any;
    defs?: any;
}
