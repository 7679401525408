import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { OrganizationStatus } from '@bucke/ngx-api';

@Component({
    selector: 'app-status-selector',
    templateUrl: './status-selector.component.html',
    styleUrls: ['./status-selector.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => StatusSelectorComponent),
        },
    ],
})
export class StatusSelectorComponent implements OnInit, ControlValueAccessor {
    @Input() disabled = false;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    readonly SupplierStatus = OrganizationStatus;

    selectedValue: OrganizationStatus;

    ngOnInit(): void {}

    // Function to call when the rating changes.
    onChange = (rating: any) => {};

    // Function to call when the input is touched
    onTouched = () => {};

    writeValue(value: any): void {
        this.selectedValue = value;
        this.setValue();
        this.onChange(value);
    }

    registerOnChange(fn: (rating: any) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    setValue(): void {
        this.onChange(this.selectedValue);
    }
}
