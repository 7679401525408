import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';

import * as fromStore from './store';
import { CustomSerializer } from './store/reducers';

@NgModule({
    imports: [
        CommonModule,

        StoreModule.forFeature('router', fromStore.reducers),
        EffectsModule.forFeature(fromStore.effects),
        StoreRouterConnectingModule.forRoot({
            serializer: CustomSerializer,
        }),
    ],
    declarations: [],
})
export class StoreRouterModule {}
