import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { LoadingService } from '../../../../shared/loading/services';

import * as fromRouterStoreActions from '@ngrx/router-store';
import * as fromRouterActions from '../actions';

@Injectable()
export class RouterEffects {
    @Effect()
    routerNavigation$: Observable<fromRouterActions.RouterActions> = this.actions$.pipe(
        ofType(fromRouterStoreActions.ROUTER_NAVIGATION),
        map(() => new fromRouterActions.LoadingStart())
    );

    @Effect()
    routerEnd$: Observable<fromRouterActions.RouterActions> = this.actions$.pipe(
        ofType(fromRouterStoreActions.ROUTER_CANCEL, fromRouterStoreActions.ROUTER_ERROR, fromRouterStoreActions.ROUTER_NAVIGATED),
        map(() => new fromRouterActions.LoadingEnd())
    );

    @Effect({ dispatch: false })
    navigate$ = this.actions$.pipe(
        ofType(fromRouterActions.ROUTER_GO),
        map((action: fromRouterActions.Go) => action.payload),
        tap(({ path }) => this.router.navigate(path))
    );

    @Effect({ dispatch: false })
    loadingStart$ = this.actions$.pipe(
        ofType(fromRouterActions.LOADING_START),
        tap(() => {
            this.loadingService.active = true;
        })
    );

    @Effect({ dispatch: false })
    loadingEnd$ = this.actions$.pipe(
        ofType(fromRouterActions.LOADING_END),
        tap(() => {
            this.loadingService.active = false;
        })
    );

    constructor(private actions$: Actions, private router: Router, private loadingService: LoadingService) {}
}
