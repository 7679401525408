import * as fromCampaign from '../actions/campaign.action';
import { CampaignDto } from '@bucke/ngx-api';

export interface CampaignState {
    loaded: boolean;
    loading: boolean;
    campaignsData: Array<CampaignDto>;
}

const initialState: CampaignState = {
    loaded: false,
    loading: false,
    campaignsData: [],
};

export const campaignReducer = (state: CampaignState = initialState, action: fromCampaign.CampaignActions): CampaignState => {
    switch (action.type) {
        // Load - Plural
        case fromCampaign.LOAD_CAMPAIGNS: {
            return {
                ...state,
                loaded: false,
                loading: true,
            };
        }

        case fromCampaign.LOAD_CAMPAIGNS_FAILURE: {
            return {
                ...state,
                loaded: false,
                loading: false,
            };
        }

        case fromCampaign.LOAD_CAMPAIGNS_SUCCESS: {
            return {
                ...state,
                campaignsData: action.payload.campaigns,
                loaded: true,
                loading: false,
            };
        }

        default:
            break;
    }

    return state;
};

// Load
export const getLoaded = (state: CampaignState) => state.loaded;
export const getLoading = (state: CampaignState) => state.loading;

export const getData = (state: CampaignState) => state.campaignsData;
