/* eslint-disable @typescript-eslint/naming-convention */
import { InjectionToken } from '@angular/core';
import { IAppConfig } from './shared/interfaces/app-config';
import { LogLevel } from '@fp/ngx-log';

export type IAvailableLanguage = 'keys' | 'en' | 'nl' | 'fr';

export const localeKeyEn = 'en';
export const localeKeyNlBE = 'nl-BE';
export const localeKeyBeFR = 'be-FR';

export class AppConfiguration {
    environmentProduction: boolean;
    logLevel: LogLevel;
    storeDevTools: boolean;

    languageAvailableLanguages: Array<IAvailableLanguage>;
    languageDefault: IAvailableLanguage;

    appInsightsInstrumentationKey: string;
    appInsightsEnableDebug: boolean;
    appInsightsEnableDebugExceptions: boolean;
    appInsightsEnableAutoRouteTracking: boolean;

    googleAnalyticsKey: string;

    apiLocation: string;

    idpIssuer: string;
    idpRedirectUri: string;
    idpPostLogoutRedirectUri: string;
    idpClientId: string;
    idpScope: string;
    idpResponseType: string;
    idpRequireHttps: boolean;
    idpShowDebugInformation: boolean;

    appWebsite: string;

    viewCampaigns: boolean;
    editCampaigns: boolean;
    createCampaigns: boolean;
    deleteCampaigns: boolean;

    showAdminParticipants: boolean;
}

export const AppConfig: IAppConfig = {
    rowLimit: 25,
    supportedCountries: {
        // TODO: Support multiple countries in the future, Belgium is currently hardcoded
        be: {
            dateFormat: 'dd/MM/yyyy',
            dateFormatCalendar: 'dd-mm-yy',
            vatNumber: /^(BE)?0[0-9]{9}$/,
        },
        nl: {
            dateFormat: 'dd/MM/yyyy',
            dateFormatCalendar: 'dd-mm-yy',
            vatNumber: /(NL)?[0-9]{9}B[0-9]{2}$/,
        },
    },
    validationRegex: {
        phone: /^(?:\+\d{1,3}|0\d{1,3}|00\d{1,2})?(?:\s?\(\d+\))?(?:[-\/\s.]|\d)+$/,
        ibanNumber: /[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,16}/,
        bicCode: /([a-zA-Z]{4})([a-zA-Z]{2})(([2-9a-zA-Z]{1})([0-9a-np-zA-NP-Z]{1}))((([0-9a-wy-zA-WY-Z]{1})([0-9a-zA-Z]{2}))|([xX]{3})|)/g,
    },
    calendarLocale: {
        firstDayOfWeek: 0,
        dayNames: [
            'DAY_NAMES_SUNDAY',
            'DAY_NAMES_MONDAY',
            'DAY_NAMES_TUESDAY',
            'DAY_NAMES_WEDNESDAY',
            'DAY_NAMES_THURSDAY',
            'DAY_NAMES_FRIDAY',
            'DAY_NAMES_SATURDAY',
        ],
        dayNamesShort: [
            'DAY_NAMES_SHORT_SUNDAY',
            'DAY_NAMES_SHORT_MONDAY',
            'DAY_NAMES_SHORT_TUESDAY',
            'DAY_NAMES_SHORT_WEDNESDAY',
            'DAY_NAMES_SHORT_THURSDAY',
            'DAY_NAMES_SHORT_FRIDAY',
            'DAY_NAMES_SHORT_SATURDAY',
        ],
        dayNamesMin: [
            'DAY_NAMES_MIN_SUNDAY',
            'DAY_NAMES_MIN_MONDAY',
            'DAY_NAMES_MIN_TUESDAY',
            'DAY_NAMES_MIN_WEDNESDAY',
            'DAY_NAMES_MIN_THURSDAY',
            'DAY_NAMES_MIN_FRIDAY',
            'DAY_NAMES_MIN_SATURDAY',
        ],
        monthNames: [
            'MONTH_NAMES_JANUARY',
            'MONTH_NAMES_FEBRUARY',
            'MONTH_NAMES_MARCH',
            'MONTH_NAMES_APRIL',
            'MONTH_NAMES_MAY',
            'MONTH_NAMES_JUNE',
            'MONTH_NAMES_JULY',
            'MONTH_NAMES_AUGUST',
            'MONTH_NAMES_SEPTEMBER',
            'MONTH_NAMES_OCTOBER',
            'MONTH_NAMES_NOVEMBER',
            'MONTH_NAMES_DECEMBER',
        ],
        monthNamesShort: [
            'MONTH_NAMES_SHORT_JANUARY',
            'MONTH_NAMES_SHORT_FEBRUARY',
            'MONTH_NAMES_SHORT_MARCH',
            'MONTH_NAMES_SHORT_APRIL',
            'MONTH_NAMES_SHORT_MAY',
            'MONTH_NAMES_SHORT_JUNE',
            'MONTH_NAMES_SHORT_JULY',
            'MONTH_NAMES_SHORT_AUGUST',
            'MONTH_NAMES_SHORT_SEPTEMBER',
            'MONTH_NAMES_SHORT_OCTOBER',
            'MONTH_NAMES_SHORT_NOVEMBER',
            'MONTH_NAMES_SHORT_DECEMBER',
        ],
        today: 'TODAY',
        clear: 'CLEAR',
    },
};

export const APP_CONFIG = new InjectionToken<IAppConfig>('app.config');
