/* eslint-disable no-underscore-dangle */
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FieldBase } from './models/field-base.model';
import { FieldControlService } from './services/field-control.service';
import { FormUpdate } from './interfaces/form-update';

@Component({
    selector: 'app-dynamic-form',
    templateUrl: './dynamic-form.component.html',
    providers: [FieldControlService],
    styleUrls: ['./dynamic-form.component.scss'],
})
export class DynamicFormComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() set fields(value) {
        this._fields = value;
        this.form = this.fcs.toFormGroup(this._fields);
        this.form$ = this.form.valueChanges.pipe(takeUntil(this.ngUnsubscribe));

        this.form$.subscribe(() => {
            this.updateForm();
        });
    }
    get fields(): Array<FieldBase<string>> {
        return this._fields;
    }

    @Output() formUpdate = new EventEmitter<any>();

    public form: UntypedFormGroup;
    public uniqueFormGroupName: string = 'formGroup' + Math.random().toString(36).substr(2, 9);

    private _fields: Array<FieldBase<string>>;
    private form$: Observable<any>;
    private readonly ngUnsubscribe = new Subject();

    constructor(private fcs: FieldControlService) {}

    ngOnInit(): void {
        this.updateForm();
    }

    ngAfterViewInit(): void {
        this.updateForm();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    updateForm(): void {
        this.formUpdate.emit({
            valid: !(this.form.status === 'INVALID'),
            dirty: !this.form.pristine,
            values: this.prepareFormValues(),
            form: this.form,
        } as FormUpdate);
    }

    prepareFormValues(): any {
        return this.form.getRawValue();
    }
}
