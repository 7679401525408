import { Action } from '@ngrx/store';

export const ROUTER_GO = '[Router] Go';

export const LOADING_START = '[Router] Navigation start';
export const LOADING_END = '[Router] Navigation end';

export class Go implements Action {
    readonly type = ROUTER_GO;
    constructor(public payload: { path: Array<string> }) {}
}

export class LoadingStart implements Action {
    readonly type = LOADING_START;
}

export class LoadingEnd implements Action {
    readonly type = LOADING_END;
}

export type RouterActions = Go | LoadingStart | LoadingEnd;
