import { Action } from '@ngrx/store';
import { actionType } from '../../../utils';
import { CampaignListDto } from '@bucke/ngx-api';

export const LOAD_CAMPAIGNS = actionType('[Campaigns] Loading campaigns');
export const LOAD_CAMPAIGNS_FAILURE = actionType('[Campaigns] Could not load campaigns');
export const LOAD_CAMPAIGNS_SUCCESS = actionType('[Campaigns] Loaded campaigns successfully');

// Load - Plural
export class LoadCampaigns implements Action {
    readonly type = LOAD_CAMPAIGNS;
    constructor(public payload?: { onlyActive?: true }) {}
}

export class LoadCampaignsFailure implements Action {
    readonly type = LOAD_CAMPAIGNS_FAILURE;
    constructor(public payload: any) {}
}

export class LoadCampaignsSuccess implements Action {
    readonly type = LOAD_CAMPAIGNS_SUCCESS;
    constructor(public payload: CampaignListDto) {}
}

// action types
export type CampaignActions = LoadCampaigns | LoadCampaignsFailure | LoadCampaignsSuccess;
