import { Directive } from '@angular/core';
import { AbstractControl, Validator, NG_VALIDATORS, ValidationErrors } from '@angular/forms';

@Directive({
    /* eslint-disable-next-line @angular-eslint/directive-selector */
    selector: '[ean-13]',
    providers: [{ provide: NG_VALIDATORS, useExisting: EAN13Validator, multi: true }],
})
export class EAN13Validator implements Validator {
    /**
     * Strips whitespaces
     *
     * @param value
     * @returns
     */
    static prepare(value: string) {
        return value.replace(/\s/g, '');
    }

    // Example barcode: 1385938273825
    static ean13(c: AbstractControl): ValidationErrors {
        // If the field is required use "Validators.required"
        if (c.value === null || c.value.length === 0) {
            return null;
        }

        const value = EAN13Validator.prepare(c.value);
        let weight = 1;
        let sum = 0;

        for (let i = 0; i < value.length - 1; i++) {
            sum += Number(value.substr(i, 1)) * weight;
            weight = (weight + 2) % 4;
        }

        const remainder = (10 - (sum % 10)) % 10;
        const valid = value.substr(value.length - 1, 1) === remainder.toString();

        // Make sure to check for length, as the formula with "123" is also valid.
        if (!valid || value.length !== 13) {
            return { ean13: true };
        }

        return null;
    }

    validate(c: AbstractControl): { [key: string]: any } {
        return EAN13Validator.ean13(c);
    }
}
