import { Inject, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';

import { Observable, of } from 'rxjs';
import { exhaustMap, map, catchError, shareReplay } from 'rxjs/operators';

import { GetUserByIdQueryResultUserDto, UsersService } from '@bucke/crm';
import { LogService } from '@fp/ngx-log';
import { AuthModuleConfig } from 'app/auth/interfaces/module';

import * as userActions from '../actions/user.action';
import * as fromSharedStore from '../../../shared/store/reducers';
import * as routerActions from '../../../store/store-router/store/actions';

@Injectable()
export class UserEffect {
    @Effect() loadUser$: Observable<userActions.UserActions | routerActions.RouterActions> = this.actions.pipe(
        ofType(userActions.LOAD_USER),
        map((action: userActions.LoadUser) => action.payload),
        exhaustMap((payload) => {
            // If user isn't personal always load fresh data
            if (payload.personal === true) {
                return this.checkPersonalUserData().pipe(exhaustMap(() => this.loadUser(payload).pipe(shareReplay(1))));
            }

            return this.loadUser(payload);
        })
    );

    constructor(
        @Inject('authConfig') private config: AuthModuleConfig,
        private readonly actions: Actions,
        private readonly store: Store<fromSharedStore.ModuleState>,
        private readonly usersService: UsersService,
        private readonly log: LogService
    ) {}

    private checkPersonalUserData(): Observable<GetUserByIdQueryResultUserDto | undefined> {
        return this.store.select(fromSharedStore.getPersonalUserData);
    }

    private loadUser(payload: { id: string; personal?: boolean }): Observable<userActions.UserActions | routerActions.RouterActions> {
        return this.usersService.apiV2UsersUserIdGet({ userId: payload.id }).pipe(
            map(
                ({ user }) =>
                    new userActions.LoadUserSuccess({
                        user,
                        personal: payload.personal !== undefined ? payload.personal : false,
                    })
            ),
            catchError((err) => {
                if (err instanceof HttpErrorResponse && err.status === 404) {
                    // With a 404 the user doesn't exist in the application (they do in the IDP)
                    return of(
                        new routerActions.Go({
                            path: this.config.unauthorizedRedirect,
                        })
                    );
                }

                this.log.error(err);

                return of(new userActions.LoadUserFailure(err));
            })
        );
    }
}
