import { NgModule, ModuleWithProviders, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { OAuthModule, OAuthService, OAuthModuleConfig, OAuthStorage } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { TranslateModule } from '@ngx-translate/core';

import { AuthRoutingModule } from './auth-routing.module';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { SharedModule } from '../shared';
import { LogService } from '@fp/ngx-log';
import { LoadingModule } from '../shared/loading/loading.module';
import { AppConfiguration } from '../app.config';
import { AuthModuleConfig } from './interfaces/module';
import { RedirectComponent, SigninComponent, SignoutComponent } from './components';
import { AuthGuard, RoleGuard, UserGuard } from './guards';
import { AuthService } from './services';

export const authConfigFactory = (): OAuthModuleConfig => ({
    resourceServer: {
        sendAccessToken: true,
    },
});

export const configureAuthFactory = (oauthService: OAuthService, appConfiguration: AppConfiguration): (() => void) => {
    oauthService.configure({
        clientId: appConfiguration.idpClientId,
        redirectUri: window.location.origin + appConfiguration.idpRedirectUri,
        postLogoutRedirectUri: appConfiguration.idpPostLogoutRedirectUri,
        scope: appConfiguration.idpScope,
        issuer: appConfiguration.idpIssuer,
        responseType: appConfiguration.idpResponseType,
        showDebugInformation: appConfiguration.idpShowDebugInformation,
        requireHttps: appConfiguration.idpRequireHttps,
    });
    oauthService.setStorage(window.localStorage);

    oauthService.tokenValidationHandler = new JwksValidationHandler();

    oauthService.clearHashAfterLogin = true;

    oauthService.loadDiscoveryDocumentAndTryLogin();

    // The APP_INITIALIZER useFactory expects a function, so give it one..
    return () => {};
};

@NgModule({
    imports: [
        // Angular
        CommonModule,
        OAuthModule.forRoot(),
        TranslateModule.forChild({
            useDefaultLang: false,
        }),

        // Custom
        AuthRoutingModule,
        SharedModule,
        LoadingModule,
    ],
    providers: [],
    declarations: [SigninComponent, SignoutComponent, RedirectComponent],
})
export class AuthModule {
    static forRoot(config?: AuthModuleConfig): ModuleWithProviders<AuthModule> {
        return {
            ngModule: AuthModule,
            providers: [
                {
                    provide: APP_INITIALIZER,
                    useFactory: configureAuthFactory,
                    deps: [OAuthService, AppConfiguration],
                    multi: true,
                },
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: AuthInterceptor,
                    multi: true,
                    deps: [LogService, OAuthService],
                },
                {
                    provide: 'authConfig',
                    useValue: config,
                },
                { provide: OAuthModuleConfig, useFactory: authConfigFactory },
                // Make sure you provide the OAuthStorage in order for it to work as the access_token stays null if you don't
                { provide: OAuthStorage, useValue: window.localStorage },

                AuthGuard,
                RoleGuard,
                UserGuard,

                AuthService,
            ],
        };
    }

    static forChild(): ModuleWithProviders<AuthModule> {
        return {
            ngModule: AuthModule,
            providers: [
                { provide: OAuthModuleConfig, useFactory: authConfigFactory },
                // Make sure you provide the OAuthStorage in order for it to work as the access_token stays null if you don't
                { provide: OAuthStorage, useValue: window.localStorage },

                AuthGuard,
                RoleGuard,
                UserGuard,

                AuthService,
            ],
        };
    }
}
