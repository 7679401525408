import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { map, first, filter } from 'rxjs/operators';

import { Roles } from '../../auth';
import * as fromSharedStore from '../store';
import { IRoleConfig, IRolePermission, IRolePermissions } from '../interfaces';
import { LogService } from '@fp/ngx-log';

@Injectable()
export class RolePermissionsResolver implements Resolve<IRolePermissions> {
    protected readonly logPrefix: string = '[RolePermissionsResolver]';

    constructor(private store: Store<fromSharedStore.ModuleState>, private log: LogService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IRolePermissions> {
        return this.store.select(fromSharedStore.getUserProfileClaims).pipe(
            filter((claims) => claims && claims.sub !== undefined && claims.sub !== null),
            first(),
            map((claims) => {
                const rolePermissions = route.data.config.rolePermissions as IRoleConfig;
                const permissions: IRolePermissions = {};

                for (const key in rolePermissions) {
                    if (!rolePermissions.hasOwnProperty(key)) {
                        continue;
                    }

                    const rolePermission = rolePermissions[key];

                    permissions[key] = this.roleCanDo(rolePermission, claims.role);
                }

                this.log.info(this.logPrefix, 'permissions', permissions);

                return permissions;
            })
        );
    }

    private roleCanDo(rolePermission: IRolePermission, userRole: Roles): { allowed: boolean } {
        const roleCan: { allowed: boolean } = {
            allowed: rolePermission.roles.indexOf(userRole) >= 0,
        };

        return roleCan;
    }
}
