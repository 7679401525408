import { Component } from '@angular/core';

import { Observable } from 'rxjs';

import { LoadingService } from '../../services';

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent {
    public active: Observable<boolean>;

    constructor(private loadingService: LoadingService) {
        this.active = this.loadingService.status;
    }
}
