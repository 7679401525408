import * as fromPromoEvent from '../actions/promo-event.action';
import { PromoDto } from '@bucke/ngx-api';

export interface PromoEventState {
    loaded: boolean;
    loading: boolean;
    promoEventsData: Array<PromoDto>;
}

const initialState: PromoEventState = {
    loaded: false,
    loading: false,
    promoEventsData: [],
};

export const promoEventReducer = (state: PromoEventState = initialState, action: fromPromoEvent.PromoEventActions): PromoEventState => {
    switch (action.type) {
        // Load - Plural
        case fromPromoEvent.LOAD_PROMO_EVENTS: {
            return {
                ...state,
                loaded: false,
                loading: true,
            };
        }

        case fromPromoEvent.LOAD_PROMO_EVENTS_FAILURE: {
            return {
                ...state,
                loaded: false,
                loading: false,
            };
        }

        case fromPromoEvent.LOAD_PROMO_EVENTS_SUCCESS: {
            return {
                ...state,
                promoEventsData: action.payload.promos,
                loaded: true,
                loading: false,
            };
        }

        default:
            break;
    }

    return state;
};

// Load
export const getLoaded = (state: PromoEventState) => state.loaded;
export const getLoading = (state: PromoEventState) => state.loading;

export const getData = (state: PromoEventState) => state.promoEventsData;
