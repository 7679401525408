import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppConfiguration } from '../app.config';

import * as fromTranslateStore from '../store/store-translate/store';

@Component({
    selector: 'app-bootstrap',
    templateUrl: './bootstrap.component.html',
    styleUrls: ['./bootstrap.component.scss'],
})
export class BootstrapComponent {
    constructor(private store: Store<fromTranslateStore.ModuleState>, private appConfiguration: AppConfiguration) {
        this.store.dispatch(new fromTranslateStore.SetDefaultLanguage(this.appConfiguration.languageDefault));
    }
}
