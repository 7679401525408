import { Route, Data } from '@angular/router';
import { Roles } from '../../auth';
import { IGroupConfig } from '../../management-panel/group/interfaces';

export interface Breadcrumb {
    /** If a label should be translated (default: false) */
    labelTranslated?: boolean;
    /** Contains the label string or label key */
    label?: string;
    /** Use the property name of the resolver, ensure this is defined */
    resolver?: string;
    /** Label that should be used from the resolved data */
    resolverLabel?: string;
}

export interface Navigation {
    label: string;
    routerLink: Array<string>;
    role: Array<Roles>;
    /**
     * This property is used only to show parts of the website based on the featureFlag,
     * please keep the type of this property up-to-date
     */
    featureFlag?: 'showAdminParticipants';
}

export interface BuckeRoute extends Route {
    data?: Data & {
        config?: IGroupConfig;
        role?: Array<Roles>;
        navigation?: Array<Navigation>;
        breadcrumb?: Breadcrumb;
    };
}

export type BuckeRoutes = Array<BuckeRoute>;
