import { Observable, UnaryFunction, pipe, of, EMPTY } from 'rxjs';
import { switchMap } from 'rxjs/operators';

/**
 * Allows you to pause an Observable where this pipe is used
 *
 * @param payload Observable<boolean>
 */
export const pausePipe = <A>(payload: Observable<boolean>): UnaryFunction<Observable<A>, Observable<A | never>> =>
    pipe(switchMap((selector) => payload.pipe(switchMap((paused) => (paused ? EMPTY : of(selector))))));
