import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as Directive from './directives';

@NgModule({
    imports: [CommonModule],
    declarations: [Directive.AppErrorMessageDirective, Directive.AppErrorMessageCaseDirective],
    exports: [Directive.AppErrorMessageDirective, Directive.AppErrorMessageCaseDirective],
})
export class ErrorMessageModule {
    constructor() {}
}
