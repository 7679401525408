import { LazyLoadEvent } from 'primeng/api';
import { IAppConfig, IPaginationFilter } from '../shared';

// Maps primgeNG to our own API filtering
export const paginationFilter = <T extends IPaginationFilter<any>>(event: LazyLoadEvent, config: IAppConfig): T =>
    ({
        skip: event.first,
        take: config.rowLimit,
        orderBy: event.sortField,
        order: event.sortField ? (event.sortOrder > 0 ? 'asc' : 'desc') : 'asc',
        pageNumber: event.first / config.rowLimit + 1,
    } as T);
