import { Action } from '@ngrx/store';
import { actionType } from '../../../utils';
import { PromoListDto } from '@bucke/ngx-api';

export const LOAD_PROMO_EVENTS = actionType('[Promo events] Loading promo events');
export const LOAD_PROMO_EVENTS_FAILURE = actionType('[Promo events] Could not load promo events');
export const LOAD_PROMO_EVENTS_SUCCESS = actionType('[Promo events] Loaded promo events successfully');

// Load - Plural
export class LoadPromoEvents implements Action {
    readonly type = LOAD_PROMO_EVENTS;
    constructor(public payload?: { onlyActive?: true }) {}
}

export class LoadPromoEventsFailure implements Action {
    readonly type = LOAD_PROMO_EVENTS_FAILURE;
    constructor(public payload: any) {}
}

export class LoadPromoEventsSuccess implements Action {
    readonly type = LOAD_PROMO_EVENTS_SUCCESS;
    constructor(public payload: PromoListDto) {}
}

// action types
export type PromoEventActions = LoadPromoEvents | LoadPromoEventsFailure | LoadPromoEventsSuccess;
