import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromTranslate from './translate.reducer';

// Types all Stores into a single Module State
export interface ModuleState {
    translateStore: fromTranslate.TranslateState;
}

// Groups all reducers
export const reducers: ActionReducerMap<ModuleState> = {
    translateStore: fromTranslate.translateReducer,
};

// Modules
export const getModuleState = createFeatureSelector<ModuleState>('translateModule');

// Stores
export const getTranslateStore = createSelector(getModuleState, (state: ModuleState) => state.translateStore);

// Translate
export const getDefault = createSelector(getTranslateStore, fromTranslate.getDefault);
export const getCurrentLanguage = createSelector(getTranslateStore, fromTranslate.getCurrentLanguage);
export const getLoaded = createSelector(getTranslateStore, fromTranslate.getLoaded);
export const getLoading = createSelector(getTranslateStore, fromTranslate.getLoading);
