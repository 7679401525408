/**
 * This function coerces a string into a string literal type.
 * Using tagged union types in TypeScript 2.0, this enables
 * powerful typechecking of our reducers.
 *
 * Since every action label passes through this function it
 * is a good place to ensure all of our action labels
 * are unique.
 *
 * @source https://github.com/xlozinguez/angular-nyc-advanced-ngrx/blob/master/src/app/store/utils/type.ts
 */

const typeCache: { [label: string]: boolean } = {};

export const actionType = <T>(label: T | ''): T => {
    if (typeCache[label as string]) {
        throw new Error(`Action type "${label}" is not unique"`);
    }

    typeCache[label as string] = true;

    return label as T;
};
