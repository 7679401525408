import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-form-select',
    templateUrl: './form-select.component.html',
    styleUrls: ['./form-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => FormSelectComponent),
        },
    ],
})
export class FormSelectComponent implements OnInit, ControlValueAccessor {
    @Input() items: Array<any>;
    @Input() placeholder: string;

    @Input() disabled = false;

    constructor() {}

    ngOnInit(): void {
        this.checkRequired();
    }

    // Function to call when the rating changes.
    onChange = (rating: any) => {};

    // Function to call when the input is touched
    onTouched = () => {};

    writeValue(value: any): void {
        this.setValue(value);
        this.onChange(value);
    }

    registerOnChange(fn: (rating: any) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    setValue(item: any): void {
        this.onChange({ value: item });
    }

    private checkRequired(): void {
        if (this.items === null) {
            throw new Error('Attribute "items" is required');
        }
    }
}
