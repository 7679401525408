/**
 * Only return property values where the value has changed
 */
export const valueDiff = ([oldValue, newValue]): { [key: string]: string } => {
    const diff = {};

    for (const key in oldValue) {
        if (!oldValue.hasOwnProperty(key)) {
            continue;
        }

        if (oldValue[key] instanceof Array) {
            if (!(newValue[key] instanceof Array)) {
                diff[key] = undefined;
            } else {
                diff[key] = oldValue[key].filter((x) => newValue[key].includes(x));
            }
        } else if (oldValue[key] !== newValue[key]) {
            // Check if value change occured
            diff[key] = newValue[key];
        }
    }

    return diff;
};
