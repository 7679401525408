import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import * as Component from './components';
import * as Service from './components';

@NgModule({
    imports: [CommonModule],
    providers: [Service.LoadingComponent],
    declarations: [Component.LoadingComponent],
    exports: [Service.LoadingComponent, Component.LoadingComponent],
})
export class LoadingModule {}
