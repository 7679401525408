/* eslint-disable @typescript-eslint/naming-convention */
import { Roles } from '../../auth';

export interface IClaims {
    name: string;
    preferred_username: string;
    given_name: string;
    family_name: string;
    role?: Roles;
    sub: string;
}
