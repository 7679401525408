import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ErrorHandlingConfigurationBaseLoader } from '../error-handling.config';

@Injectable({
    providedIn: 'root',
})
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(private errorHandlingConfiguration: ErrorHandlingConfigurationBaseLoader) {}

    /**
     * Handles error for the 4xx and 5xx range http status codes
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error) => {
                // The 401 status is handled by the AuthModule
                if (error instanceof HttpErrorResponse && error.status !== 401) {
                    this.errorHandlingConfiguration.getHttpErrorResponseCallback()(error);
                }

                return throwError(error);
            })
        );
    }
}
