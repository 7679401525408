import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import * as Store from './store';

@NgModule({
    imports: [
        CommonModule,

        // Third party
        TranslateModule.forChild({
            useDefaultLang: false,
        }),

        StoreModule.forFeature('translateModule', Store.reducers),
        EffectsModule.forFeature(Store.effects),
    ],
    declarations: [],
})
export class StoreTranslateModule {}
