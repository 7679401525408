import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { map, filter, first } from 'rxjs/operators';

import { LocalStorageService } from 'ngx-webstorage';

import { OAuthService } from 'angular-oauth2-oidc';

import { LogService } from '@fp/ngx-log';

import { afterLoginTargetKey } from '../constants/storage';
import { AuthService } from '../services';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    protected readonly logPrefix: string = '[AuthGuard]';

    // We store the URL that was initially navigated to in LocalStorage
    protected readonly targetStorageKey: string = afterLoginTargetKey;

    constructor(
        private logService: LogService,
        private oauthService: OAuthService,
        private storage: LocalStorageService,
        private authService: AuthService
    ) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        this.logService.info(this.logPrefix, 'Initialize');

        return this.authService.loadSharedDiscoveryDocument().pipe(
            first(),
            map(() => {
                this.logService.info(this.logPrefix, 'Validation');

                if (this.oauthService.hasValidAccessToken()) {
                    return true;
                }

                const target = window.location.pathname;

                // This is a cold observable
                this.storage
                    .observe(this.targetStorageKey)
                    .pipe(
                        filter((t) => t !== undefined),
                        first()
                    )
                    .subscribe(() => {
                        this.logService.info(this.logPrefix, 'Redirect after login', target);

                        // Redirect user to IDP. Also allows us to redirect to the previous path after login.
                        // Except it doesn't redirect correctly so we do it ourselves
                        this.oauthService.initImplicitFlow(target);
                    });

                this.storage.store(this.targetStorageKey, target);

                return false;
            })
        );
    }
}
