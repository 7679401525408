import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';

import { of, Observable, defer } from 'rxjs';
import { map, exhaustMap, first, catchError } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import { LogService } from '@fp/ngx-log';
import { LocalStorageService } from 'ngx-webstorage';
import { AppConfiguration } from '../../../../app.config';

import * as fromTranslateActions from '../../store/actions';
import * as fromTranslateReducer from '../../store/reducers';

@Injectable()
export class TranslateEffect {
    @Effect() setDefaultLanguage$ = this.actions$.pipe(
        ofType(fromTranslateActions.SET_DEFAULT_LANGUAGE),
        map((action: fromTranslateActions.SetDefaultLanguage) => action.payload),
        exhaustMap((payload) => {
            this.translateService.setDefaultLang(payload);

            return this.translateService.onDefaultLangChange.pipe(
                first(),
                map(() => new fromTranslateActions.SetDefaultLanguageSuccess(payload)),
                catchError((error) => {
                    this.log.error(error);

                    return of(new fromTranslateActions.SetDefaultLanguageFailure(error));
                })
            );
        })
    );

    @Effect() setCurrentLanguage$ = this.actions$.pipe(
        ofType(fromTranslateActions.SET_CURRENT_LANGUAGE),
        map((action: fromTranslateActions.SetCurrentLanguage) => action.payload),
        exhaustMap((payload) =>
            this.translateService.use(payload).pipe(
                map(() => {
                    this.storage.store(this.currentLanguageKey, payload);

                    return new fromTranslateActions.SetCurrentLanguageSuccess(payload);
                }),
                catchError((error) => {
                    this.log.error(error);

                    return of(new fromTranslateActions.SetCurrentLanguageFailure(error));
                })
            )
        )
    );

    // Weird deferred effect to bypass bug (https://github.com/ngrx/platform/issues/103#issuecomment-320966449)
    @Effect({ dispatch: false }) init$: Observable<Action> = defer(() => {
        const language = this.storage.retrieve(this.currentLanguageKey);

        // Is used to dispatch all initial acttions upon EffectModule initialization
        this.store.dispatch(
            new fromTranslateActions.SetCurrentLanguage(language !== null ? language : this.appConfiguration.languageDefault)
        );
    });

    private readonly currentLanguageKey = 'currentLanguage';

    constructor(
        public readonly store: Store<fromTranslateReducer.ModuleState>,
        private readonly actions$: Actions,
        private readonly translateService: TranslateService,
        private readonly log: LogService,
        private readonly storage: LocalStorageService,
        private readonly appConfiguration: AppConfiguration
    ) {}
}
