import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { filter, exhaustMap, tap, first } from 'rxjs/operators';

import * as fromSharedStore from '../store';
import { LogService } from '@fp/ngx-log';
import { GetUserByIdQueryResultUserDto } from '@bucke/crm';

@Injectable()
export class PersonalUserResolver implements Resolve<GetUserByIdQueryResultUserDto> {
    protected readonly logPrefix: string = '[PersonalUserResolver]';

    constructor(private store: Store<fromSharedStore.ModuleState>, private log: LogService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<GetUserByIdQueryResultUserDto> {
        return this.store.select(fromSharedStore.getUserProfileClaims).pipe(
            filter((claims) => claims && claims.sub !== undefined && claims.sub !== null),
            first(),
            exhaustMap((claims) => {
                this.store.dispatch(
                    new fromSharedStore.LoadUser({
                        id: claims.sub,
                        personal: true,
                    })
                );

                return this.store.select(fromSharedStore.getPersonalUserData).pipe(
                    filter((user) => user !== undefined),
                    first(),
                    tap((user) => {
                        this.log.info(this.logPrefix, 'user', user);

                        this.store.dispatch(
                            new fromSharedStore.LoadUserSuccess({
                                user,
                                personal: true,
                            })
                        );
                    })
                );
            })
        );
    }
}
