import { Injectable, ErrorHandler } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { LogService } from '@fp/ngx-log';
import { ApplicationInsightsService } from '../services';

@Injectable({
    providedIn: 'root',
})
export class AppInsightsErrorHandler implements ErrorHandler {
    constructor(private logService: LogService, private applicationInsightsService: ApplicationInsightsService) {}

    handleError(error: Error | HttpErrorResponse): void {
        if (error instanceof HttpErrorResponse || !this.applicationInsightsService.appInsightsEnabled) {
            // Http errors should be handled when using the HttpClient
            return;
        }

        this.logService.error(error);
    }
}
