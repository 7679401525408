import { Injectable, Inject } from '@angular/core';
import { Observable, from } from 'rxjs';
import { catchError, share, exhaustMap, map, tap } from 'rxjs/operators';

import { OAuthService } from 'angular-oauth2-oidc';
import { LogService } from '@fp/ngx-log';
import { IClaims } from '../../shared';
import { AuthModuleConfig, IAuthorizedRedirect } from '../interfaces/module';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    // eslint-disable-next-line @typescript-eslint/ban-types
    sharedDiscoveryDocument$: Observable<object>;
    sharedUserProfile$: Observable<IClaims>;

    constructor(private oauthService: OAuthService, private log: LogService, @Inject('authConfig') private config: AuthModuleConfig) {}

    // eslint-disable-next-line @typescript-eslint/ban-types
    loadSharedDiscoveryDocument(): Observable<object> {
        if (this.sharedDiscoveryDocument$ !== undefined) {
            return this.sharedDiscoveryDocument$;
        }

        this.sharedDiscoveryDocument$ = from(this.oauthService.loadDiscoveryDocument()).pipe(share());

        return this.sharedDiscoveryDocument$;
    }

    loadUserProfile(refresh?: boolean): Observable<IClaims> {
        if (this.sharedUserProfile$ !== undefined && !refresh) {
            return this.sharedUserProfile$;
        }

        // The "role" claim is only accessible through the endpoint
        this.sharedUserProfile$ = from(this.oauthService.loadUserProfile()).pipe(
            catchError((error) => {
                this.log.error('Failed to load user profile', error);

                return error;
            }),
            map((claims) => claims as IClaims)
        );

        return this.sharedUserProfile$;
    }

    /**
     * Returns the redirection object based on the role
     */
    redirectFromClaim(): Observable<IAuthorizedRedirect> {
        const redirects = this.config.authorizedRedirect;

        return this.loadSharedDiscoveryDocument().pipe(
            exhaustMap(() => this.loadUserProfile()),
            map((claims) =>
                redirects.find((redirect) => {
                    const matchingRoleRedirect = redirect.roles.findIndex((role) => role === claims.role);

                    return matchingRoleRedirect !== -1;
                })
            )
        );
    }

    refreshProfile(): void {
        this.oauthService.silentRefresh();
    }
}
