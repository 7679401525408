import { OrderDirection } from '@bucke/ngx-api';

export interface IPaginationFilter<T> {
    skip?: number;
    take?: number;
    orderBy?: T; // Use type any to allow extending with string literals
    order?: OrderDirection;
    filter?: string;
    pageNumber?: number;
}
