import { Inject, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Component, Input } from '@angular/core';
import { IAppConfig } from '@bucke/shared/interfaces';
import { TranslateService } from '@ngx-translate/core';
import { APP_CONFIG } from 'app/app.config';
import { GanttChart, GanttChartOptions } from '../gantt-chart';

@Component({
    selector: 'app-gantt-chart-zoom',
    templateUrl: './gantt-chart-zoom.component.html',
    styleUrls: ['./gantt-chart-zoom.component.scss'],
})
export class GanttChartZoomComponent {
    @Input() set data(value: any) {
        this.chartData = value;
        this.svgHeight = this.chartData?.length > 0 ? 100 + this.chartData.length * 70 : 0;
        this.buildGraph();
    }

    @Input() set height(value: any) {
        this.svgHeight = value;
        this.buildGraph();
    }

    @Input() set width(value: any) {
        this.svgWidth = value;
        this.buildGraph();
    }

    @Input() set dMin(value: string) {
        this.domainMin = value;
    }

    @Input() set dMax(value: string) {
        this.domainMax = value;
    }

    @Input() set locale(value: string) {
        this.months = this.config.calendarLocale.monthNames.map((monthKey) => this.translateService.instant(monthKey));
        this.buildGraph();
    }

    @Output() public itemSelect = new EventEmitter<string>();

    private chartData: any;
    private svgWidth = 0;
    private svgHeight = 0;
    private readonly margin = { top: 20, right: 20, bottom: 110, left: 160 };
    private readonly margin2 = { top: 430, right: 20, bottom: 30, left: 160 };
    private domainMin = '';
    private domainMax = '';
    private months = [];

    constructor(@Inject(APP_CONFIG) private readonly config: IAppConfig, private readonly translateService: TranslateService) {}

    buildGraph(): void {
        const options = new GanttChartOptions(
            '#chart',
            this.chartData,
            this.svgHeight,
            this.svgWidth,
            this.margin,
            this.margin2,
            this.domainMin,
            this.domainMax,
            this.months
        );

        document.getElementById('chart').innerHTML = '';
        const gc = new GanttChart(this.translateService, options).build();
        document.getElementById('chart').addEventListener('select', this.onSelect.bind(this));
    }

    onSelect(event): void {
        this.itemSelect.emit(event.detail);
    }
}
