/* eslint-disable @typescript-eslint/ban-types */
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InjectionToken, NgModule, PLATFORM_ID } from '@angular/core';
import { HttpClientModule, HttpClient, HttpErrorResponse } from '@angular/common/http';

// Third party
import { TranslateModule, TranslateLoader, MissingTranslationHandler } from '@ngx-translate/core';
import { TranslateHttpLoader } from './utils/http-loader';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsConfig, StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { APP_CONFIG, AppConfig, AppConfiguration } from './app.config';
import { AppComponent } from './app.component';
import { MainComponent } from './components/main/main.component';
import { AuthModule, Roles } from './auth';
import { SharedModule } from './shared';
import { LogModule, LogConfigurationBaseLoader, LogConfigurationLoader } from '@fp/ngx-log';
import {
    ApiModule as LegacyApiModule,
    ApiConfigurationBaseLoader as LegacyConfigurationBaseLoader,
    ConfigurationParameters as LegacyConfigurationParameters,
    ApiConfigurationLoader as LegacyConfigurationLoader,
} from '@bucke/ngx-api';
import { ApiModule, ConfigurationLoader, ConfigurationBaseLoader, ConfigurationParameters } from '@bucke/crm';
import { ErrorHandlingModule } from './error-handling/error-handling.module';
import { ErrorHandlingConfigurationBaseLoader, ErrorHandlingConfigurationLoader } from './error-handling/error-handling.config';
import { HttpRequestFailed } from './core/store';
import { ErrorService } from './error-handling/services';

import { AppRoutingModule } from './app-routing.module';
import { BootstrapComponent } from './bootstrap/bootstrap.component';
import { CustomMissingTranslationHandler } from './utils/missing-translation-handler';
import { StoreRouterModule } from './store/store-router/store-router.module';
import { StoreTranslateModule } from './store/store-translate/store-translate.module';
import { WindowService } from './shared/window';
import { isPlatformBrowser } from '@angular/common';
import { environment } from 'environments/environment';

export const createTranslateLoader = (http: HttpClient): TranslateLoader =>
    new TranslateHttpLoader(http, './assets/locales/strings-', '.json');

export const logFactory = (appConfiguration: AppConfiguration): LogConfigurationBaseLoader =>
    // Allows you to set a custom. For example, based on the environment configuration.
    new LogConfigurationLoader(appConfiguration.logLevel);

export const legacyApiFactory = (platformId: InjectionToken<object>): LegacyConfigurationBaseLoader => {
    const config: LegacyConfigurationParameters = {};

    config.basePath = environment.configuration.apiLocation;

    // Allows you to set a custom. For example, based on the environment configuration.
    return new LegacyConfigurationLoader(config);
};

export const apiFactory = (platformId: InjectionToken<object>): ConfigurationBaseLoader => {
    const config: ConfigurationParameters = {};

    config.basePath = environment.configuration.apiLocation;

    // Allows you to set a custom. For example, based on the environment configuration.
    return new ConfigurationLoader(config);
};

export const errorHandlingFactory = (store: Store<any>, errorService: ErrorService): ErrorHandlingConfigurationBaseLoader => {
    // Dispatches an action when we get a HttpErrorResponse (expect 401)
    const httpErrorResponseCallbackCallback = (error: HttpErrorResponse): void => {
        store.dispatch(
            HttpRequestFailed({
                response: errorService.serializeResponse(error),
            })
        );
    };

    return new ErrorHandlingConfigurationLoader(httpErrorResponseCallbackCallback);
};

export const legacyApiModule = LegacyApiModule.forRoot({
    configuration: {
        provide: LegacyConfigurationBaseLoader,
        deps: [PLATFORM_ID],
        useFactory: legacyApiFactory,
    },
});

export const apiModule = ApiModule.forRoot({
    configuration: {
        provide: ConfigurationBaseLoader,
        deps: [PLATFORM_ID],
        useFactory: apiFactory,
    },
});

@NgModule({
    declarations: [AppComponent, MainComponent, BootstrapComponent],
    imports: [
        // Angular
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,

        // Third party
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useClass: CustomMissingTranslationHandler,
            },
            useDefaultLang: false,
        }),

        // NGRX
        StoreModule.forRoot({}),
        EffectsModule.forRoot([]),
        StoreDevtoolsModule.instrument(),
        StoreRouterModule,
        StoreTranslateModule,

        // Custom
        ErrorHandlingModule.forRoot({
            configuration: {
                provide: ErrorHandlingConfigurationBaseLoader,
                useFactory: errorHandlingFactory,
                deps: [Store, ErrorService],
            },
        }),
        legacyApiModule,
        apiModule,
        AuthModule.forRoot({
            unauthorizedRedirect: ['/unauthorized'],
            authorizedRedirect: [
                {
                    path: ['admin-panel'],
                    roles: [Roles.Admin],
                },
                {
                    path: ['management-panel', 'dashboard'],
                    roles: [Roles.SchoolAdmin, Roles.CityAdmin],
                },
            ],
            unallowedRedirectTargets: ['/signin-oidc.html', '/'],
            afterLoginTargetKey: 'afterLoginTarget',
        }),
        LogModule.forRoot({
            configuration: {
                provide: LogConfigurationBaseLoader,
                useFactory: logFactory,
                deps: [AppConfiguration],
            },
        }),
        AppRoutingModule,
        SharedModule,
    ],
    providers: [
        {
            provide: APP_CONFIG,
            useValue: AppConfig,
        },
        {
            provide: StoreDevtoolsConfig,
            deps: [AppConfiguration],
            useFactory: (appConfiguration: AppConfiguration) => ({
                logOnly: appConfiguration.environmentProduction,
                maxAge: 25,
            }),
        },
        WindowService,
    ],
    bootstrap: [BootstrapComponent],
})
export class AppModule {}
