import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Router } from '@angular/router';

import { Subject } from 'rxjs';

import { AuthService } from '../../services';
import { OAuthService } from 'angular-oauth2-oidc';
import { LogService } from '@fp/ngx-log';
import { LoadingService } from '../../../shared/loading/services';
import { takeUntil } from 'rxjs/operators';
import { AuthModuleConfig } from '../../interfaces/module';

@Component({
    selector: 'app-redirect',
    templateUrl: './redirect.component.html',
    styleUrls: ['./redirect.component.scss'],
})
export class RedirectComponent implements OnInit, OnDestroy {
    private ngUnsubscribe = new Subject();

    constructor(
        private router: Router,
        private log: LogService,
        private loadingService: LoadingService,
        private authService: AuthService,
        private oauthService: OAuthService,
        @Inject('authConfig') private config: AuthModuleConfig
    ) {}

    ngOnInit(): void {
        this.loadingService.start();

        // The sole purpose of this page is just to redirect the user based on the role to the correct page
        this.authService
            .redirectFromClaim()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (redirect) => {
                    if (!!redirect) {
                        this.router.navigate(redirect.path);

                        return;
                    }

                    // No role matches, so the user is unauthorized
                    this.router.navigate(this.config.unauthorizedRedirect);
                },
                (error) => {
                    this.log.error(error);
                    this.oauthService.initImplicitFlow();
                }
            );
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();

        this.loadingService.stop();
    }
}
