import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { EmailValidator } from '@bucke/forms/validators/email';
import { FieldBase } from '../models/field-base.model';

@Injectable()
export class FieldControlService {
    constructor() {}

    toFormGroup(fields: Array<FieldBase<any>>): UntypedFormGroup {
        const group: { [key: string]: AbstractControl } = {};

        fields.forEach((field) => {
            switch (field.controlType) {
                case 'textbox':
                    group[field.key] = field.required
                        ? new UntypedFormControl(field.value || null, Validators.required)
                        : new UntypedFormControl(field.value || null);
                    break;
                case 'email':
                    group[field.key] = field.required
                        ? new UntypedFormControl(field.value || null, [Validators.required, EmailValidator.email])
                        : new UntypedFormControl(field.value || null, EmailValidator.email);
                    break;
                case 'telephone':
                    group[field.key] = field.required
                        ? new UntypedFormControl(field.value || null, Validators.required)
                        : new UntypedFormControl(field.value || null);
                    break;
                case 'checkbox':
                    group[field.key] = field.required
                        ? new UntypedFormControl(field.value || null, Validators.requiredTrue)
                        : new UntypedFormControl(field.value || null);
                    break;
                default:
                    group[field.key] = field.required
                        ? new UntypedFormControl(field.value || null, Validators.required)
                        : new UntypedFormControl(field.value || null);
            }
        });

        return new UntypedFormGroup(group);
    }
}
