import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { MainComponent } from './components/main/main.component';
import { BuckeRoutes } from './shared';

const routes: BuckeRoutes = [
    {
        path: '',
        component: AppComponent,
        children: [
            {
                // Uses dummy component to redirect, because redirect not working with empty-path top-level routes
                // Check https://github.com/angular/angular/issues/10726 for more info.
                path: '',
                component: MainComponent,
            },
            {
                path: 'admin-panel',
                loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
            },
            {
                path: 'management-panel',
                loadChildren: () => import('./management-panel/management-panel.module').then((m) => m.ManagementPanelModule),
            },
            {
                path: 'registration',
                loadChildren: () => import('./registration/registration.module').then((m) => m.RegistrationModule),
            },
            {
                path: 'account',
                loadChildren: () => import('./account/account.module').then((m) => m.AccountModule),
            },
            {
                path: 'unauthorized',
                loadChildren: () => import('./unauthorized/unauthorized.module').then((m) => m.UnauthorizedModule),
            },
        ],
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
            paramsInheritanceStrategy: 'always',
            relativeLinkResolution: 'legacy',
        }),
    ],
    exports: [RouterModule],
    providers: [],
})
export class AppRoutingModule {}
