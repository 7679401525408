import { Component, OnInit, OnDestroy, Inject } from '@angular/core';

import { Router, NavigationEnd } from '@angular/router';

import { Subject } from 'rxjs';
import { filter, debounceTime, exhaustMap, map, first, takeUntil } from 'rxjs/operators';

import { LocalStorageService } from 'ngx-webstorage';

import { LogService } from '@fp/ngx-log';
import { LoadingService } from '../../../shared/loading/services';

import { AuthModuleConfig } from '../../interfaces/module';
import { AuthService } from '../../services';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
    selector: 'app-signin',
    templateUrl: './signin.component.html',
    styleUrls: ['./signin.component.scss'],
})
export class SigninComponent implements OnInit, OnDestroy {
    protected readonly logPrefix: string = '[SigninComponent]';
    private ngUnsubscribe = new Subject();

    constructor(
        private logService: LogService,
        private storage: LocalStorageService,
        private loadingService: LoadingService,
        private router: Router,
        @Inject('authConfig') private config: AuthModuleConfig,
        private authService: AuthService,
        private oauthService: OAuthService
    ) {}

    ngOnInit(): void {
        this.loadingService.start();
        this.logService.info(this.logPrefix, 'Commence target evaluation');

        if (!this.router.url.includes('#')) {
            // Else if you would visit this route without a '#' in the URL nothing will happen, blank page
            if (this.oauthService.hasValidAccessToken()) {
                this.router.navigate(['role-redirect']);
            } else {
                this.oauthService.initImplicitFlow();
            }
        }

        // the oAuth library triggers a 'popstate' and 'hashchange' event which would redirect us back to signin
        // after initial login, wait for those events to finish before evaluating
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                filter((event: NavigationEnd) => !this.targetIsValid(event.urlAfterRedirects)),
                debounceTime(200), // Prevent flashy load screen
                exhaustMap(() =>
                    // Retrieve claims to do a role check and redirect later on
                    this.authService.redirectFromClaim().pipe(
                        first(),
                        map((redirect) => ({
                            redirect,
                            target: this.storage.retrieve(this.config.afterLoginTargetKey),
                        }))
                    )
                ),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(({ redirect, target }) => {
                if (!!redirect) {
                    // If a target is specified and valid we we redirect them to it
                    if (this.targetIsValid(target)) {
                        this.router.navigate([target]);

                        this.logService.info(this.logPrefix, 'Actual redirect after login', { target });
                    } else {
                        // Redirect to given url based on the role
                        this.router.navigate(redirect.path);
                    }
                } else {
                    this.logService.error(this.logPrefix, 'No redirect available for given route, please check if new roles where added!');
                    this.router.navigate(this.config.unauthorizedRedirect);
                }
            });
    }

    ngOnDestroy(): void {
        // After we're done signing in, clear the target
        this.loadingService.stop();
        this.storage.clear(this.config.afterLoginTargetKey);

        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    private targetIsValid(target: string): boolean {
        return target && target.length > 0 && this.config.unallowedRedirectTargets.indexOf(target) < 0;
    }
}
