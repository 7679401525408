import { Injectable } from '@angular/core';

// eslint-disable-next-line no-underscore-dangle
const _window = (): any =>
    // return the global native browser window object
    window;
@Injectable()
export class WindowService {
    get nativeWindow(): any {
        return _window();
    }
}
