import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxWebstorageModule } from 'ngx-webstorage';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { TranslateModule } from '@ngx-translate/core';

import { DropdownModule } from 'primeng/dropdown';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TooltipModule } from 'primeng/tooltip';

import * as Components from './components';
import * as Store from './store';
import * as Validator from './validators';
import * as Resolver from './resolvers';
import * as Directive from './directives';
import * as Guard from './guards';

import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { DynamicFormFieldComponent } from './components/dynamic-form/dynamic-form-field.component';
import { InputTextModule } from 'primeng/inputtext';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { TableModule } from 'primeng/table';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

import { ErrorMessageModule } from 'app/error-message/error-message.module';
import { CheckboxModule } from 'primeng/checkbox';
import { GanttChartZoomComponent } from './components/gantt-chart/gantt-chart-zoom/gantt-chart-zoom.component';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';

@NgModule({
    imports: [
        // Angular
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        // PrimeNg
        DropdownModule,
        RadioButtonModule,
        TooltipModule,
        TranslateModule.forChild({
            useDefaultLang: false,
        }),
        InputTextModule,
        ReactiveFormsModule,
        FormsModule,
        DialogModule,
        RadioButtonModule,
        FormsModule,
        ButtonModule,
        CardModule,
        TableModule,
        InputMaskModule,
        InputNumberModule,
        CheckboxModule,
        ProgressSpinnerModule,
        CalendarModule,
        ConfirmDialogModule,

        ErrorMessageModule,

        NgxWebstorageModule.forRoot({
            prefix: 'bucke',
            caseSensitive: true,
        }),

        TranslateModule.forChild({
            useDefaultLang: false,
        }),

        // Custom
        StoreModule.forFeature('sharedModule', Store.reducers),
        EffectsModule.forFeature(Store.effects),
    ],
    declarations: [
        Validator.DecimalValidator,
        Validator.EAN13Validator,
        Validator.EmailValidator,
        Validator.PinCodeValidator,

        Directive.NumberInputDirective,
        Components.FormSelectComponent,
        Components.StatusSelectorComponent,

        DynamicFormComponent,
        DynamicFormFieldComponent,
        GanttChartZoomComponent,
    ],
    providers: [Resolver.PersonalUserResolver, Resolver.RolePermissionsResolver, Guard.PendingChangesGuard, ConfirmationService],
    exports: [
        Validator.DecimalValidator,
        Validator.EAN13Validator,
        Validator.EmailValidator,
        Validator.PinCodeValidator,

        Directive.NumberInputDirective,
        Components.FormSelectComponent,
        Components.StatusSelectorComponent,

        TooltipModule,
        DynamicFormComponent,
        DynamicFormFieldComponent,
        GanttChartZoomComponent,

        // PrimeNg
        ReactiveFormsModule,
        DropdownModule,
        RadioButtonModule,
        InputTextModule,
        FormsModule,
        DialogModule,
        RadioButtonModule,
        FormsModule,
        ButtonModule,
        CardModule,
        TableModule,
        InputMaskModule,
        InputNumberModule,
        CheckboxModule,
        ProgressSpinnerModule,
        CalendarModule,
        ConfirmDialogModule,
    ],
})
export class SharedModule {}
