import { Component, Inject, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { IAppConfig } from '@bucke/shared/interfaces';
import { DateUtils } from '@bucke/shared/utils/date-utils';
import { TranslateService } from '@ngx-translate/core';
import { APP_CONFIG } from 'app/app.config';
import { FieldBase } from './models/field-base.model';
import { DateField } from './models/field-date';
import { PrimeNGConfig } from 'primeng/api';

@Component({
    selector: 'app-field',
    templateUrl: './dynamic-form-field.component.html',
    styleUrls: ['./dynamic-form-field.component.scss'],
})
export class DynamicFormFieldComponent implements OnInit {
    @Input() set field(value: FieldBase<any>) {
        this.currentField = value;

        this.setDateFieldProperties(value);
    }
    get field(): FieldBase<any> {
        return this.currentField;
    }

    @Input() form: UntypedFormGroup;

    public get isDirty(): boolean {
        return !this.control.pristine;
    }

    public get isValid(): boolean {
        return this.control.status === 'VALID';
    }

    public get showError(): boolean {
        return this.control.errors && !this.control.pristine;
    }

    public get control(): AbstractControl {
        return this.form.get(this.field.key);
    }

    public minDate: Date = null;
    public maxDate: Date = null;
    public disabledDates: Array<Date> = null;
    public disabledDays: Array<number> = null;
    public disabledMonths: Array<number> = null;

    private currentField: FieldBase<any>;

    constructor(
        @Inject(APP_CONFIG) readonly config: IAppConfig,
        readonly translate: TranslateService,
        private primeNgConfig: PrimeNGConfig
    ) {
        this.translate.get('primeng').subscribe((res) => this.primeNgConfig.setTranslation(res));
    }

    ngOnInit(): void {}

    public onCalendarIsVisible(months: Array<number>): void {
        const date = this.form.get(this.field.key).value || new Date();

        this.disableMonth({ month: date.getMonth() + 1, year: date.getFullYear() }, months);
    }

    public disableMonth({ month, year }, months: Array<number>): void {
        const currentMonth = month - 1;

        if (months.includes(currentMonth)) {
            const dates = [];

            const lastDay = DateUtils.getLastDayOfMonth(currentMonth, year);

            for (let currentDay = 1; currentDay <= lastDay; currentDay++) {
                dates.push(new Date(year, currentMonth, currentDay));
            }

            this.disabledDates = this.removeDuplicateDates([...(this.disabledDates || []), ...dates]);
        }
    }

    private setDateFieldProperties(value: FieldBase<any>): void {
        if (value.controlType === 'datefield') {
            const field = value as DateField;

            this.minDate = field.minimumDate || null;
            this.maxDate = field.maximumDate || null;
            this.disabledDates = field.disabledDates || null;
            this.disabledMonths = field.disabledMonths || null;
            this.disabledDays = field.disabledDays || null;
        }
    }

    private removeDuplicateDates(list: Array<Date>): Array<Date> {
        return list
            .map((date) => date.getTime())
            .filter((date, i, array) => array.indexOf(date) === i)
            .map((time) => new Date(time));
    }
}
