import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

/**
 * Allows for configuring a callback
 */
export abstract class ErrorHandlingConfigurationBaseLoader {
    /**
     * Abstract class used for custom configuration
     */
    abstract getHttpErrorResponseCallback(): (error: HttpErrorResponse) => void;
}

/**
 * Contains the module configurations
 */
export class ErrorHandlingConfigurationLoader implements ErrorHandlingConfigurationBaseLoader {
    /** Contains the callback on HttpErrorResponse (except 401)  */
    httpErrorResponseCallbackCallback: (error: HttpErrorResponse) => void;

    /**
     * Used for providing a callback, defaults to () => {}
     */
    constructor(httpErrorResponseCallbackCallback?: (error: HttpErrorResponse) => void) {
        if (httpErrorResponseCallbackCallback !== undefined) {
            this.httpErrorResponseCallbackCallback = httpErrorResponseCallbackCallback;
        }
    }

    /**
     * Contains the callback that was set during module initializating in forRoot
     */
    public getHttpErrorResponseCallback(): (error: HttpErrorResponse) => void {
        return this.httpErrorResponseCallbackCallback;
    }
}

/**
 * Used for testing purposes
 */
@Injectable()
export class ErrorHandlingConfigurationFakeLoader extends ErrorHandlingConfigurationBaseLoader {
    /** Contains the callbackk on HttpErrorResponse (except 401)  */
    httpErrorResponseCallbackCallback: (error: HttpErrorResponse) => void;

    /**
     * Returns the callback that was set during module initializating in forRoot
     */
    getHttpErrorResponseCallback(): (error: HttpErrorResponse) => void {
        return this.httpErrorResponseCallbackCallback;
    }
}
