export class DateUtils {
    static monthsLastDay = {
        january: 31,
        februari: 28,
        march: 31,
        april: 30,
        may: 31,
        june: 30,
        july: 31,
        august: 31,
        september: 30,
        october: 31,
        november: 30,
        december: 31,
    };

    static toLocalDateString(dateInput: Date | string): string {
        return new Date(dateInput).toLocaleDateString('nl-BE', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        });
    }

    static toISODateString(dateInput: Date | string): string {
        return DateUtils.toPrimeNgCalendarString(dateInput).replace(/\//g, '-');
    }

    static dateIsDuringSchoolPeriod(date: Date, schoolyear: number = new Date().getFullYear()): boolean {
        const firstDay = DateUtils.getFirstSchoolDay(schoolyear);
        const lastDay = DateUtils.getLastSchoolDay(schoolyear + 1);

        return firstDay <= date && lastDay >= date;
    }

    /**
     * Convert YEAR-MONTH-DAY HOUR-MINUTES-SECONDS to
     * p-calendar setting: dateFormat="yy-mm-dd"
     */
    static toPrimeNgCalendarString(dateInput: string | Date): string {
        return DateUtils.toLocalDateString(dateInput).split('/').reverse().join('/');
    }

    static getLastDayOfMonth(month: number, year: number): number {
        return month === Months.februari && year % 4 === 0 ? 29 : DateUtils.monthsLastDay[Months[month]];
    }

    static getFirstDateOfMonth(month: Date | number, year?: number): Date {
        return DateUtils.getXDateOfMonth(() => 1, month, year);
    }

    static getLastDateOfMonth(month: Date | number, year?: number): Date {
        return DateUtils.getXDateOfMonth(DateUtils.getLastDayOfMonth, month, year);
    }

    static getXDateOfMonth(day: (m: number, y: number) => number, month: Date | number, year?: number): Date {
        let localYear: number;
        let localMonth: number;

        if (typeof month === 'number') {
            if (!year) {
                throw new Error('if month is number, you need to provide year as well');
            }

            localYear = year;
            localMonth = month;
        } else {
            localYear = month.getFullYear();
            localMonth = month.getMonth();
        }

        return new Date(localYear, localMonth, day(localMonth, localYear));
    }

    static determineSchoolyear(dateInput: Date, year: number = dateInput.getFullYear()): number {
        if (DateUtils.dateIsDuringSchoolPeriod(dateInput, year)) {
            return year;
        }

        const previousYear = DateUtils.getLastSchoolDay(year);

        if (previousYear >= dateInput) {
            return DateUtils.determineSchoolyear(dateInput, year - 1);
        }

        const nextYear = DateUtils.getFirstSchoolDay(year + 1);

        if (nextYear <= dateInput) {
            return DateUtils.determineSchoolyear(dateInput, year + 1);
        }

        return year;
    }

    static getLastSchoolDay(year: number | string): Date {
        return new Date(DateUtils.getLastSchoolDayString(year));
    }

    static getFirstSchoolDay(year: number | string): Date {
        return new Date(DateUtils.getFirstSchoolDayString(year));
    }

    static getLastSchoolDayString(year: number | string): string {
        return `${year}-06-30T23:59:59`;
    }

    static getFirstSchoolDayString(year: number | string): string {
        return `${year}-09-01T00:00:00`;
    }

    static addDateTo(date: Date, days: number, months: number = 0, years: number = 0): Date {
        return new Date(date.getFullYear() + years, date.getMonth() + months, date.getDate() + days);
    }

    static addDateToday(days: number, months: number = 0, years: number = 0): Date {
        return this.addDateTo(new Date(), days, months, years);
    }

    static startOfDay(date: Date): Date {
        const updatedDate = new Date(date);

        updatedDate.setHours(0, 0, 0, 0);

        return updatedDate;
    }
}

// eslint-disable-next-line no-shadow
export enum Months {
    january = 0,
    februari = 1,
    march = 2,
    april = 3,
    may = 4,
    june = 5,
    july = 6,
    august = 7,
    september = 8,
    october = 9,
    november = 10,
    december = 11,
}

// eslint-disable-next-line no-shadow
export enum Days {
    sunday = 0,
    monday = 1,
    tuesday = 2,
    wednesday = 3,
    thursday = 4,
    friday = 5,
    saturday = 6,
}
