export interface Country {
    name: string;
    code: string;
}

export interface CountryState {
    countryData: Array<Country>;
}

const initialState: CountryState = {
    countryData: [
        {
            code: 'BE',
            name: 'Belgium',
        },
    ],
};

export const countryReducer = (state: CountryState = initialState): CountryState => state;

// Load
export const getData = (state: CountryState) => state.countryData;
