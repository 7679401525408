/* eslint-disable @typescript-eslint/ban-types */
/**
 * This directive is used to only display one message at a time. You can feed this
 * directive the error object of the input field you want to validate. Use
 * appErrorMessageCase and use the property name you want to validate on. Keep in mind
 * that it will use the first property it matches on.
 *
 * @example
 * <small [appErrorMessage]="email.errors">
 *    <span *appErrorMessageCase="'required'">E-mailaddress is required</span>
 *    <span *appErrorMessageCase="'validateEmail'">E-mailaddress is invalid</span>
 * </small>
 *
 * This directive is inspired on the ngSwitch directive
 * @see angular/modules/@angular/common/src/directives/ng_switch.ts
 */
import { Directive, DoCheck, OnChanges, Host, Input, TemplateRef, ViewContainerRef } from '@angular/core';

export class SwitchErrorView {
    private created = false;

    constructor(private viewContainerRef: ViewContainerRef, private templateRef: TemplateRef<object>) {}

    create(): void {
        this.created = true;
        this.viewContainerRef.createEmbeddedView(this.templateRef);
    }

    destroy(): void {
        this.created = false;
        this.viewContainerRef.clear();
    }

    enforceState(created: boolean): void {
        if (created && !this.created) {
            this.create();
        } else if (!created && this.created) {
            this.destroy();
        }
    }
}

@Directive({
    selector: '[appErrorMessage]',
})
export class AppErrorMessageDirective implements OnChanges {
    private internalErrorMessage: any;
    private hasMatched: boolean;
    private matchedValue: string;

    constructor() {
        this.hasMatched = false;
    }

    @Input()
    set appErrorMessage(newValue: object) {
        this.internalErrorMessage = newValue;
    }

    /** @internal */
    _matchCase(value: any): boolean {
        let matched = false;

        if (value !== undefined && value !== null && this.internalErrorMessage !== undefined && this.internalErrorMessage !== null) {
            matched = this.internalErrorMessage.hasOwnProperty(value);

            if (this.matchedValue === value) {
                return true;
            }

            if (this.hasMatched) {
                return false;
            }

            if (matched && !this.hasMatched) {
                this.hasMatched = true;
                this.matchedValue = value;

                return true;
            }
        }

        return matched;
    }

    // Reset our match when the error message object is altered
    ngOnChanges(): void {
        this.hasMatched = false;
        this.matchedValue = '';
    }
}

@Directive({
    selector: '[appErrorMessageCase]',
})
export class AppErrorMessageCaseDirective implements DoCheck {
    @Input() appErrorMessageCase: any;

    private view: SwitchErrorView;

    constructor(
        viewContainer: ViewContainerRef,
        templateRef: TemplateRef<object>,
        @Host() private appErrorMessage: AppErrorMessageDirective
    ) {
        this.view = new SwitchErrorView(viewContainer, templateRef);
    }

    ngDoCheck(): void {
        // eslint-disable-next-line no-underscore-dangle
        this.view.enforceState(this.appErrorMessage._matchCase(this.appErrorMessageCase));
    }
}
