import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AppConfiguration } from './app.config';
import { LogService } from '@fp/ngx-log';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    private ngUnsubscribe = new Subject();

    constructor(private router: Router, private log: LogService, private appConfiguration: AppConfiguration) {
        // Set Google Analytics key
        ga('create', this.appConfiguration.googleAnalyticsKey, 'auto');
    }

    ngOnInit(): void {
        ga('set', 'page', this.router.url);
        ga('send', 'pageview');

        this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event) => {
            if (event instanceof NavigationEnd) {
                // this will find & use the ga function pulled via the google scripts
                try {
                    ga('set', 'page', event.urlAfterRedirects);
                    ga('send', 'pageview');

                    this.log.info(`Logging: ${event.urlAfterRedirects} to google analytics`);
                } catch {
                    this.log.error('Tracking not found - make sure you installed the scripts');
                }
            }
        });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
